// store/index.js
import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
    state() {
        return {
            user: null, // สถานะเริ่มต้นของ user เป็น null
        };
    },
    mutations: {
        // mutation สำหรับการเปลี่ยนแปลง user state
        setUser(state, user) {
            state.user = user;
        },
        setMappingId(state, mappingId) {
            if (state.user) {
                state.user.mappingId = mappingId;
            }
        },
    },
    actions: {
        async fetchUser({ commit }, userData) {
            if (userData.userId) {
                try {
                    // เรียกใช้ API หรือระบบหลังบ้านเพื่อรับข้อมูลผู้ใช้
                    const params = {
                        userId: userData.userId,
                        providerId: process.env.VUE_APP_DUECHAT_PROVIDER, // 1 LINE, 2 Facebook
                        storeCode: process.env.VUE_APP_DUECHAT_STORE // can not get value
                    }
                    const rs = await axios.post(`/user-profile`, params)
                    console.log(rs)
                    const memberData = rs.data

                    if (memberData && memberData.mappingId) {
                        userData = {
                            ...userData,
                            ...memberData
                        }
                    }
                    // เรียกใช้ mutation เพื่ออัปเดต state ใน store
                    commit('setUser', userData);
                } catch (error) {
                    console.error('Error fetching user:', error);
                    // จัดการข้อผิดพลาดหรืออัปเดต state เพื่อแสดงข้อผิดพลาด
                }
            }
        },
    },
    getters: {
        // getter สำหรับการเข้าถึง user state
        getUser: (state) => state.user,
    },
});
