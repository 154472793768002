<template>
  <div id="app">
    <!-- ส่วนหัว, แถบนำทาง, หรืออื่นๆ ที่คุณต้องการแสดงในทุกหน้า -->
    <router-view/>
    <!-- ส่วนท้าย, หรืออื่นๆ ที่คุณต้องการแสดงในทุกหน้า -->
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

