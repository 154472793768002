import { createRouter, createWebHistory } from 'vue-router';
import liff from '@line/liff';
import store from '../store';
const routes = [
    {
        path: '/home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
        meta: { requiresAuth: false }, // สามารถเข้าชมได้โดยสาธารณะ
    },
    {
        path: '/',
        name: 'Member',
        component: () => import(/* webpackChunkName: "member" */ '../views/MemberView.vue'),
        meta: { requiresAuth: true }, // ต้องการตรวจสอบการเข้าสู่ระบบ
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import(/* webpackChunkName: "register" */ '../views/RegisterView.vue'),
        meta: { requiresAuth: true }, // ต้องการตรวจสอบการเข้าสู่ระบบ
    },
    // ... เส้นทางอื่นๆ ที่ไม่ต้องการตรวจสอบการเข้าสู่ระบบสามารถเพิ่ม meta: { requiresAuth: false } หรือไม่กำหนด meta ก็ได้
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // ตรวจสอบสถานะเข้าสู่ระบบด้วย LIFF เฉพาะเส้นทางที่ต้องการ
        if (!liff.isLoggedIn()) {
            liff.login();
        } else {
            try {
                const profile = await liff.getProfile();
                await store.dispatch('fetchUser', profile);
                const user = store.state.user;
                if (user?.mappingId) {
                    if (to.name === 'Register') {
                        next({ name: 'Member' });
                    } else {
                        next();
                    }
                } else {
                    if (to.name !== 'Register') {
                        next({ name: 'Register' });
                    } else {
                        next();
                    }
                }
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        }
    } else {
        next(); // ถ้าเส้นทางไม่ต้องการตรวจสอบการเข้าสู่ระบบ ให้ไปต่อได้เลย
    }
});


export default router;
